import { Stack } from '@ads-core/components';
import { FormFieldSection, FormTracker } from '@sitecore-jss/sitecore-jss-forms';
import { FieldWithValueProps } from '@sitecore-jss/sitecore-jss-react-forms';

import { useIsConditionallyHidden } from '../hooks';
import { formFieldsFactory } from '..';
import { PropsWithConditions } from '../types';

type SectionFieldProps = PropsWithConditions<FieldWithValueProps<FormFieldSection>>;

export const SectionFieldMapper = ({
  field,
  tracker,
  currentPage,
  totalAmountOfSteps,
  isLoading,
  stepBackHandler,
}: {
  field: SectionFieldProps['field'];
  tracker: FormTracker;
  currentPage: number;
  totalAmountOfSteps: number;
  isLoading: boolean;
  stepBackHandler: (step?: number) => void;
}) => {
  const { fieldKey } = field.model.conditionSettings;

  const isHidden = useIsConditionallyHidden({ fieldKey });
  if (isHidden) return null;

  return (
    <fieldset className={`${field.model.cssClass} section-field`}>
      <Stack gap={6}>
        {formFieldsFactory(
          field.fields,
          tracker,
          currentPage,
          totalAmountOfSteps,
          stepBackHandler,
          isLoading
        )}
      </Stack>
    </fieldset>
  );
};
