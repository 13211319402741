import React from 'react';
import { Heading } from '../Heading';
import { ButtonLink } from '../ButtonLink';
import { Tag } from '../Tag';
import { Stack } from '../Stack';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { Accordion } from '../Accordion';
import { Box } from '../Box';
import { ScrollLinkWrapper } from '../ScrollLinkWrapper';

type FaqProps = {
  items: {
    content: React.ReactNode;
    title: React.ReactNode;
    titleName?: string | number;
    id: string;
    anchor?: string;
  }[];
  tag?: React.ReactNode;
  title?: React.ReactNode;
  button?: React.ReactElement;
  onAccordionOpen?: (id: string | undefined, previousId: string | undefined) => void;
};

export const Faq = (props: FaqProps) => {
  return <FaqView {...props} />;
};

export const FaqSitecore = (props: FaqProps) => {
  return <FaqView {...props} />;
};

const FaqView = ({ title, tag, button, items, onAccordionOpen }: FaqProps) => {
  const [openedItem, setOpenedItem] = React.useState<string>();

  return (
    <PageGrid>
      <GridItem
        asChild
        columnStart={{ initial: '1', md: '3' }}
        columnEnd={{ initial: '-1', md: '-3' }}
      >
        <Stack gap={10} alignX="start" isFullWidth>
          {tag ? <Tag>{tag}</Tag> : null}
          {title ? (
            <Heading size="h2" asChild>
              {title}
            </Heading>
          ) : null}
          {items ? (
            <Accordion
              type="single"
              onAccordionOpen={(id) => {
                setOpenedItem(id);

                if (onAccordionOpen) {
                  onAccordionOpen(id, openedItem);
                }
              }}
            >
              {items.map((item, i) => {
                const isActive = openedItem === item.id;

                return (
                  <ScrollLinkWrapper anchorId={item.anchor} key={`${i}-${item.id}`}>
                    <Accordion.Container firstChild={i === 0} boxed key={`${i}-${item.id}`}>
                      <Accordion.Item
                        {...item}
                        content={<Box paddingTop={10}>{item.content}</Box>}
                        isActive={isActive}
                        icon={
                          <Accordion.Icon
                            variant={
                              isActive
                                ? { initial: 'transparant', md: 'primary' }
                                : { initial: 'transparant', md: 'solid' }
                            }
                            size={'small'}
                            label={item.titleName}
                            isActive={isActive}
                          />
                        }
                      />
                    </Accordion.Container>
                  </ScrollLinkWrapper>
                );
              })}
            </Accordion>
          ) : null}
          {button ? (
            <ButtonLink asChild variant="ghost">
              {button}
            </ButtonLink>
          ) : null}
        </Stack>
      </GridItem>
    </PageGrid>
  );
};

