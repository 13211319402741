import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { InternalComponentProps } from '../Slot';
import { Loader } from '../Loader';
import { ToneFocus, ToneVariants } from '../ToneFocusProvider';
import { VisuallyHidden } from '../VisuallyHidden';
import { button, ButtonVariants } from './Button.css';

export type ButtonProps = InternalComponentProps & {
  children: React.ReactNode;
  /** The icon before the link text */
  beforeIcon?: React.ReactNode;
  /** The icon after the link text */
  afterIcon?: React.ReactNode;
  asChild?: boolean;
  isLoading?: boolean;
} & ButtonVariants &
  ButtonHTMLAttributes<HTMLButtonElement> &
  ToneVariants;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      beforeIcon,
      afterIcon,
      tone = 'onLight',
      size = 'large',
      variant = 'primary',
      onClick,
      type = 'button',
      className = '',
      style = {},
      asChild,
      isLoading,
      ...rest
    },
    ref
  ) => {
    const loaderTone = React.useMemo(() => {
      switch (variant) {
        case 'primary':
          return 'onDark';
        case 'secondary':
          return 'onLight';
        case 'ghost':
          return 'onLight';
        case 'ghostOnDark':
          return 'onDark';
        default:
          return 'onDark';
      }
    }, [variant]);
    const loaderComponent = <Loader size="xs" color={loaderTone} />;

    if (asChild) {
      return (
        <ToneFocus tone={tone}>
          <Slot
            style={style}
            className={clsx(button({ size, variant }), className)}
            ref={ref}
            {...rest}
          >
            {children}
          </Slot>
        </ToneFocus>
      );
    }

    return (
      <>
        {isLoading && (
          <VisuallyHidden aria-live="polite">Laden...</VisuallyHidden>
        )}
        <ToneFocus tone={tone}>
          <button
            type={type}
            style={style}
            className={clsx(button({ size, variant }), className)}
            ref={ref}
            onClick={(e) => {
              if (onClick) onClick(e);
            }}
            aria-disabled={rest.disabled || isLoading}
            {...rest}
          >
            {beforeIcon && isLoading ? loaderComponent : beforeIcon}
            {children}
            {afterIcon && isLoading ? loaderComponent : afterIcon}
            {!afterIcon && !beforeIcon && isLoading ? loaderComponent : null}
          </button>
        </ToneFocus>
      </>
    );
  }
);
